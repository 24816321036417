/* An Cascading Style Sheet that provides a few, very basic (and admittedly ad-hoc) styles */
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                  sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 2px;
    padding: 2px;
}

/* 2 column grid layout */
.twocols {
    display: grid;
    grid-template-columns: 200px 200px;
}

.twocols * {
    padding: 3px;
}

/* For horizontal layout of tab heading */
ul#menu li {
    display: inline;
    margin: 2rem;
    color: darkblue;
    font-size: larger;
}

.tab {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 1rem;
    height: 75vh;
    overflow-y: scroll;
}

/* Attach this to a tab before calling await on a fetch, and remove after calling .json() */
.spinner {
    background: url("images/Loading_icon.gif") no-repeat fixed center;
}
